import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const Education = () => {
  const { education } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  console.debug({ education });

  return (
    <section id="education">
      <Container>
        <hr style={{ width: '100%' }} />
        <Title title="Education" className="education-title" />
        <hr style={{ width: '70%' }} />
        {!!education.length && (
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
            <div>
              {education.map(
                (
                  { institutionName, institutionLogo, degree, qualification, fromDate, toDate },
                  index
                ) => {
                  console.debug({ institutionName, fromDate, toDate })
                  return (
                    <>
                      {index === 0 && <br style={{ marginTop: '4em' }} />}
                      <div>
                        <Title title={`${institutionName}`} size="medium" />

                        <hr style={{ width: '60%' }} />
                        <p>Qualification: {qualification + (degree ?? '')}</p>
                        <p>From: {fromDate}</p>
                        <p>To: {toDate}</p>

                        <img className="education-institution-logo" alt="" src={institutionLogo} />
                      </div>
                      <br style={{ marginTop: '2em', marginBottom: '2em' }} />
                    </>
                  );
                }
              )}
            </div>
          </Fade>
        )}
      </Container>
    </section>
  );
};

export default Education;
