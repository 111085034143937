import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const WorkExperience = () => {
  const { workExperience } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  console.log({ workExperience })

  return (
    <section id="workexperience">
      <Container>
        <Title title="Work Experience" />
        <hr style={{ width: '70%' }} />
        {
            !!workExperience.length && (<Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                {
                    workExperience.map(({ companyName, companyLogo, companyLogoStyle, role, fromDate, toDate }, index) => {
                        return (<>
                        { index == 0 && <br style={{ marginTop: "2em" }}/>}
                        <div>
                            <Title title={`${companyName}`} size="medium" />
                            <hr style={{ width: '60%' }} />
                            <p>{role}</p>
                            <p>From: {fromDate}</p>
                            <p>To: {toDate}</p>

                            <img className={`${companyLogoStyle}`} src={companyLogo}/>
                        </div>
                        { !!(index != workExperience.length -1 ) && <br style={{ marginTop: "2em", marginBottom: "2em" }}/>}
                        </>)
                    })
                }
            </Fade>)
        }
      </Container>
    </section>
  );
};

export default WorkExperience;
