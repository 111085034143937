import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const Title = ({ title, size }) => {
  let titleText = null
  switch(size) {
    case "small":
      titleText = <h3>{title}</h3>
      break;
    case "medium":
      titleText = <strong><h2>{title}</h2></strong>
      break;
    case "large":
    default:
      titleText = <h1>{title}</h1>
  }
  
  
  return <Fade bottom duration={1000} delay={300} distance="0px">
      {titleText}
  </Fade>
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default Title;
