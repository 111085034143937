import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Badge } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const Interests = () => {
  const { about } = useContext(PortfolioContext);
  const { interests } = about;

  // eslint-disable-next-line no-unused-vars
  const [_, setIsDesktop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [__, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  function shuffle(array) {
    const suffledArray = [...array];
    let currentIndex = suffledArray.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      [suffledArray[currentIndex], suffledArray[randomIndex]] = [
        suffledArray[randomIndex],
        suffledArray[currentIndex],
      ];
    }

    return suffledArray;
  }

  return (
    <section id="interests">
      <Container>
        <Title title="Interests" />
        <Row className="interests-wrapper">
          <Fade duration={1000} delay={2000} distance="30px">
            <Row className="interests">
              {shuffle(interests || []).map((interest) => (
                <Badge className="interests-badge">
                  <p className="interests-badge-text">{interest}</p>
                </Badge>
              ))}
            </Row>
          </Fade>
        </Row>
      </Container>
    </section>
  );
};

export default Interests;
